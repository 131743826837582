<template>
  <div>
    
    <v-row class="mt-4" v-if="form_page && form_page.fields && form_page.fields.in_personnr">
      <v-col lg="6">
        
        <memlist-text-input
          v-if="!skip"
          id="in_personnr"
          name="in_personnr"
          validation_type="PERSONNR"
          layout="vertical"
          :title="$t('PUBLIC.FORM.PERSONNR')"
          v-model="local_form_page_data.in_personnr"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.FORM.PERSONNR')"
          :required="true"
          :invalid_text="$t('PUBLIC.FORM.INVALID_PERSONNR')"
          @validated="validated"
          
          >
        </memlist-text-input>

        <p v-else>{{$t('PAGES.REGISTRATION.SELECTED_SKIP_PERSONNR')}}</p>

        <div v-if="!form_page.fields.in_personnr.required" class="d-flex align-items-center mb-4 mt-8">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" v-model="skip" @input="skip_clicked"/>
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('PAGES.REGISTRATION.SKIP_PERSONNR')}}</span>
        </div>
        

      </v-col>
    </v-row>

    
    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.FORM.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        v-if="form_page.index != 0"
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <b-spinner v-if="spinning" small type="grow"></b-spinner>
        {{ $t('COMMON.CONTINUE') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'FormPagePersonnr',
  mixins: [ toasts ],

  props: {
    
    form_page_data: { /* data for the entire form, previously entered, all pages */
      type: Object,
      default: () => ({})
    },
    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    },
    form_pages: { /* we need to find the parent page */
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      // -- parent data
      local_parent_page_form_data: {},
      parent_form_page: {},
      // -- parent data


      local_form_page_data: {},

      skip: false,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {

    console.log('FormPagePersonnr mounted() form_page', this.form_page);
    console.log('FormPagePersonnr mounted() form_page_data', this.form_page_data);

    this.local_form_page_data = { ...this.form_page_data };
  },
  watch: {
    form_page_data: {
      handler(newValue) {
        this.local_form_page_data = { ...this.form_page_data }
      },
      deep: true
    }
  },
  methods: {
    on_back() {
      this.$emit('back', this.form_page, this.local_form_page_data);
    },

    skip_clicked() {},

    update_form(field_name, value) {
      this.local_form_page_data[field_name] = value;

      this.local_form_page_data = { ...this.local_form_page_data };
    },
    validated(field_name, value, valid) {
      this.local_form_page_data[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form_page, this.local_form_page_data);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>
